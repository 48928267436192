<template>
  <div v-if="page" class="page">

    <div class="outer-container">
      <div class="inner-container">

        <h1 class="font-bold mt-12">{{page.elements.title.value}}</h1>

        <div class="mt-12 exhibitors">
          <h2 class="font-bold uppercase">Exhibitors</h2>
          <div class="sort flex -mt-8 justify-end items-center">
            <div class="flex">
              <span class="main-text block mr-4">Sort by</span>
              <select v-model="exhibitorSort" class="width-full border-b border-white px-0">
                <option value="Alphabetical">Alphabetical</option>
                <option value="Distributor">Distributor</option>
                <option value="Stand">Stand</option>
                <option value="Region">Region</option>
              </select>
            </div>
          </div>
          <div class="mt-12 border-t border-gray-300">
            <div class="list">

              <div class="item w-full" v-for="item in sortedExhibitors" :key="item.system.id">
                <div class="inner border-b border-gray-300 py-4">
                  <div class="flex justify-between items-center">
                    <div class="uppercase exhibitor-name font-bold">{{ modular_content[item.elements.winery.value[0]].elements.name.value }}</div>
                    <div class="main-text text-sm">{{ modular_content[item.elements.winery.value[0]].elements.state__zone__region.value }}</div>
                  </div>
                  <div class="stand flex gap-2 items-center">
                    <svg class="block" width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg" v-if="item.elements.stall.value">
                      <path
                          d="M10.3594 3.67188C10.3594 5.06836 8.48047 7.53125 7.66797 8.54688C7.49023 8.80078 7.10938 8.80078 6.93164 8.54688C6.11914 7.53125 4.26562 5.06836 4.26562 3.67188C4.26562 1.99609 5.61133 0.625 7.3125 0.625C8.98828 0.625 10.3594 1.99609 10.3594 3.67188ZM7.3125 4.48438C7.87109 4.48438 8.32812 4.05273 8.32812 3.46875C8.32812 2.91016 7.87109 2.45312 7.3125 2.45312C6.72852 2.45312 6.29688 2.91016 6.29688 3.46875C6.29688 4.05273 6.72852 4.48438 7.3125 4.48438ZM10.791 5.19531C10.8164 5.16992 10.8164 5.14453 10.8418 5.11914L13.7871 3.92578C14.168 3.77344 14.625 4.05273 14.625 4.48438V11.3652C14.625 11.6191 14.4727 11.8477 14.2188 11.9492L10.5625 13.3965V5.72852C10.6387 5.55078 10.7148 5.37305 10.791 5.19531ZM3.80859 5.19531C3.88477 5.37305 3.96094 5.55078 4.0625 5.72852V12.1016L0.8125 13.3965C0.431641 13.5488 0 13.2695 0 12.8379V5.95703C0 5.70312 0.126953 5.47461 0.380859 5.39844L3.47852 4.1543C3.55469 4.50977 3.65625 4.86523 3.80859 5.19531ZM8.30273 9.05469C8.6582 8.62305 9.2168 7.91211 9.75 7.09961V13.4473L4.875 12.0508V7.09961C5.38281 7.91211 5.94141 8.62305 6.29688 9.05469C6.80469 9.71484 7.79492 9.71484 8.30273 9.05469Z"
                          fill="#E8490F"/>
                    </svg>
                    <div class="font-bold uppercase" v-if="item.elements.stall.value">{{ item.elements.stall.value }}</div>
                    <div class="main-text" v-if="item.elements.by.value">by</div>
                    <div class="font-bold" v-if="item.elements.by.value">{{ item.elements.by.value }}</div>
                  </div>
                </div>
              </div>

            </div>
          </div>

        </div>


      </div>
    </div>
  </div>
</template>

<script>
import Kontent from "@/kontent";
import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
dayjs.extend(utc);
dayjs.extend(timezone);
export default {
  name: 'ExperienceEvent',
  metaInfo() {
    if (!this.page) {
      return null
    }

    let meta = [
      {property: 'og:site_name', content: 'CONNECT'},
      {property: 'og:url', content: window.location.href},
      {
        property: 'og:title',
        content: this.page.elements.page__meta_title.value.length ? this.page.elements.page__meta_title.value : this.page.elements.page__page_title.value
      },
      {property: 'og:description', content: this.page.elements.page__page_description.value},
      {
        name: 'title',
        content: this.page.elements.page__meta_title.value.length ? this.page.elements.page__meta_title.value : this.page.elements.page__page_title.value
      },
      {name: 'keywords', content: this.page.elements.page__meta_keywords.value},
      {
        name: 'description',
        content: this.page.elements.page__meta_description.value.length ? this.page.elements.page__meta_description.value : this.page.elements.page__page_description.value
      },
      {name: 'twitter:card', content: 'summary_large_image'},
      {
        name: 'twitter:title',
        content: this.page.elements.page__meta_title.value.length ? this.page.elements.page__meta_title.value : this.page.elements.page__page_title.value
      },
      {
        name: 'twitter:description',
        content: this.page.elements.page__meta_description.value.length ? this.page.elements.page__meta_description.value : this.page.elements.page__page_description.value
      }
    ]

    // if there's an image
    if (this.page.elements.page__main_image.value.length) {
      meta.push({property: 'og:image', content: this.page.elements.page__main_image.value[0].url})
      meta.push({name: 'twitter:image', content: this.page.elements.page__main_image.value[0].url})
    }

    // if there's a type
    if (this.page.elements.page__page_type.value.length) {
      meta.push({property: 'og:type', content: this.page.elements.page__page_type.value[0].name})
    }

    return {
      title: this.page.elements.page__page_title.value,
      meta: meta
    }
  },
  props: {},
  data() {
    return {
      page: null,
      modular_content: null,
      exhibitorSort: 'Alphabetical'
    }
  },
  mounted() {

    const self = this
    Kontent.getExperienceEvent(this.$route.params.event).then((res) => {
      self.page = res.data.items[0]
      self.modular_content = res.data.modular_content

      setTimeout(function () {
        window.prerenderReady = true
        window.print();
      }, 1000)
    })

    document.body.classList.add('print-exhibitors')
  },
  methods: {},
  computed: {
    dayjs() {
      return dayjs
    },
    exhibitors() {
      return this.page.elements.exhibitors_list.value.map((item) => {
        return this.modular_content[item]
      })
    },
    sortedExhibitors() {
      if (this.exhibitorSort === 'Alphabetical') {
        return [...this.exhibitors].sort((a, b) => {
          return this.modular_content[a.elements.winery.value[0]].elements.name.value.localeCompare(this.modular_content[b.elements.winery.value[0]].elements.name.value)
        })
      } else if (this.exhibitorSort === 'Distributor') {
        return [...this.exhibitors].sort((a, b) => {
          return a.elements.by.value.localeCompare(b.elements.by.value)
        })
      } else if (this.exhibitorSort === 'Stand') {
        return [...this.exhibitors].sort((a, b) => {
          if (parseInt(a.elements.stall.value) < parseInt(b.elements.stall.value)) {
            return -1;
          }
          if (parseInt(a.elements.stall.value) > parseInt(b.elements.stall.value)) {
            return 1;
          }
          // a must be equal to b
          return 0;
        })
      } else if (this.exhibitorSort === 'Region') {
        return [...this.exhibitors].sort((a, b) => {
          return this.modular_content[a.elements.winery.value[0]].elements.state__zone__region.value.localeCompare(this.modular_content[b.elements.winery.value[0]].elements.state__zone__region.value)
        })
      } else {
        return this.exhibitors
      }
    },
    days () {
      return this.page.elements.days.value.map((item) => {
        return this.modular_content[item]
      })
    },
  }
}
</script>

<style lang="scss">
body.print-exhibitors {
  background-color: #fff;
  color: #000;

  header, footer {
    display: none;
  }
}
</style>

<style scoped lang="scss">
.page {
  line-height: 1.6;
}
</style>
